import { BaseProps } from 'types';
import { mergeRefs } from 'utils';

export interface SectionProps extends BaseProps {
  bgImage?: string;
  customRef?: React.Ref<HTMLElement> | null;
}

const Section: React.FC<SectionProps> = ({ bgImage, className, children, customRef }) => (
  <section
    className={className}
    style={{ ...(bgImage && { backgroundImage: `url('${bgImage}')` }) }}
    ref={mergeRefs([customRef])}
  >
    {children}
  </section>
);

export default Section;
